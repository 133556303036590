import React, { useState } from 'react';
import parse from 'html-react-parser';

import PageSubtitle from '../Subpage/subtitle';

import './style.css';

import loadComponents from '../Loadable';

const H3 = loadComponents('h3');

export default function FaqBlock({ title, data }) {
  const [currentFaq, setCurrentFaq] = useState(null);

  const handleClick = (e, index) => {
    e.preventDefault();
    const active = currentFaq === index ? null : index;
    setCurrentFaq(active);
  };

  return (
    <div className="subpageComponent">
      {title && <PageSubtitle title={title} />}
      <ul>
        {data.map((item, index) => {
          const key = `faq${index}`;
          return (
            <li
              key={key}
              className={`noBullet faq${index === currentFaq ? ' active' : ''}`}
              style={{
                marginTop: index !== 0 ? `2.8125rem` : `0`,
              }}
            >
              <H3
                size
                margin="0px"
                className="faqQuestion"
                onClick={e => handleClick(e, index)}
              >
                {item.question}
              </H3>
              <div
                className="answer"
                style={{
                  overflow: `hidden`,
                  transition: `all 0.5s linear`,
                }}
              >
                <div className="inner" style={{ marginTop: `45px` }}>
                  {parse(item.answer)}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
